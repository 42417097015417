<template>
  <div
    class="banner"
    :class="`banner__type--${type}`"
    :style="{
      '--fillColor': `${ICON_BY_TYPE[type].color}`
    }"
  >
    <icon
      :name="ICON_BY_TYPE[type].icon"
      :fill="ICON_BY_TYPE[type].color"
      :stroke="ICON_BY_TYPE[type].stroke"
      class="banner__icon"
    />
    <div class="banner__text">
      <h3 v-if="title">{{ title }}</h3>
      <template v-if="content && content.length">
        <p
          v-for="(p, i) in content"
          :key="i"
        >{{ p }}</p>
      </template>
    </div>
  </div>
</template>


<script type="ts">
import { defineComponent } from 'vue';
import CONSTANTS from '@/utils/constants';
import Icon from '@/components/Icon.vue';

const ICON_BY_TYPE = {
  [CONSTANTS.ALERT_TYPES.SUCCESS]: { icon: 'ok', color: '#74af26' },
  [CONSTANTS.ALERT_TYPES.WAIT]: { icon: 'wait', color: '#9B9B9B' },
  [CONSTANTS.ALERT_TYPES.ERROR]: { icon: 'warn', color: '#F4825F' },
  [CONSTANTS.ALERT_TYPES.WARNING]: { icon: 'warn', color: '#9B9B9B' },
};

export default defineComponent({
  name: 'AlertBanner',
  components: { Icon },
  props: {
    type: {
      type: String,
      validator: (s) => Object.values(CONSTANTS.ALERT_TYPES).includes(s),
      required: true,
    },
    title: { type: String, default: null },
    content: { type: Array, default: () => [] },
  },
  data() {
    return {
      ICON_BY_TYPE,
    };
  },
  computed: {},
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_vars.scss';

.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 20px 20px 10px;
  border-radius: 4px;
  border-radius: 6px;
  border: var(--fillColor) 4px solid;
  &.banner__type--success {
    border: none;
    background: #eee;
    .banner__text {
      color: #888
    }
  }
}
.banner__icon {
  display: block;
  flex-shrink: 0;
  fill: var(--fillColor);
  flex-shrink: 0;
  // margin-right: 15px;
  width: 80px;
  height: 80px;
}
.banner__text {
  color: var(--fillColor);
  flex-grow: 1;
  p {
    margin-bottom: .25em;
    display: block;
    flex-grow: 1;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
