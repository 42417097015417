
import Banner from '@/components/Banner.vue';
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import CONSTS from '@/utils/constants';

export default defineComponent({
  name: 'confirmation',
  components: { Banner },
  setup() {
    const store = useStore();
    const curState = computed(() => store.getters['user/currentState']);
    const STATES = CONSTS.USER_STATES;
    const confirmedPayment = computed(
      () => store.getters['user/paymentConfirmed']
    );
    const confirmedAllocation = computed(
      () => store.getters['user/allocationConfirmed']
    );
    const tokenCost = computed(() => store.getters['app/tokenCost']);
    const tokenCount = computed(() => Math.floor(confirmedPayment.value / tokenCost.value));
    return {
      CONSTS,
      STATES,
      curState,
      tokenCount,
      confirmedPayment,
      confirmedAllocation
    }
  }
})
